<template>
  <div class="lists-wrapper__lists">
    <div class="grid-wrapper">
      <div class="col col-12">
        <div class="grid-info">
          <span class="grid-heading">
            <h1>{{ $t('Pick lists') }}</h1>
            <span class="entries"
              >{{ NumberFormat(entries) }}
              {{ $t(`entr${entries == 1 ? 'y' : 'ies'}`) }}</span
            >
          </span>
          <div class="grid-actions">
            <a class="button" @click.prevent="CreateListShow" href="">{{
              $t('Create')
            }}</a>
          </div>
          <div class="grid-search">
            <input
              type="text"
              @keypress.enter="Search"
              :value="search.query"
              :placeholder="$t('Search by list ID')"
            />
          </div>
        </div>
        <table class="list table odd-even">
          <thead>
            <tr class="list__actions">
              <th class="id">
                <a
                  :class="[
                    page.order.by == 'id' ? 'active' : '',
                    page.order.direction,
                  ]"
                  @click.prevent="SetOrderBy('id')"
                  href=""
                >
                  <span>{{ $t('List ID') }}</span>
                </a>
              </th>
              <th class="state">{{ $t('Done') }}</th>
              <th class="orders">{{ $t('Orders') }}</th>
              <th class="wagon-size">{{ $t('Wagon size') }}</th>
              <th class="created-at">{{ $t('Created') }}</th>
              <th class="created-by">{{ $t('Created by') }}</th>
              <th class="edit"></th>
            </tr>
          </thead>
          <tbody>
            <tr
              :data-id="list.id"
              class="list__row clickable"
              @mousedown="ClickOpen"
              @mousemove="ClickOpen"
              @mouseup="ClickOpen"
              :key="index"
              v-for="(list, index) in lists"
            >
              <td class="id">{{ list.id }}</td>
              <td>
                <div :class="['state', list.is_done ? 'positive' : 'negative']">
                  {{ $t(list.is_done ? 'Yes' : 'No') }}
                </div>
              </td>
              <td class="orders">{{ NumberFormat(list.item_count) }}</td>
              <td class="wagon-size">{{ NumberFormat(list.wagon_size) }}</td>
              <td class="created-at">{{ DateFormat(list.create_date) }}</td>
              <td class="created-by">{{ list.create_by }}</td>
              <td
                @mouseenter="SetBackground"
                @mouseleave="SetBackground"
                class="actions"
              >
                <a @click.prevent href="" class="icon dots">
                  <ul class="item-actions">
                    <li>
                      <a @click.prevent="ViewListShow(list.id)" href="">{{
                        $t('View details')
                      }}</a>
                    </li>
                    <li>
                      <a
                        @click.prevent="ViewPickPath(list)"
                        href=""
                        class="success"
                        >{{ $t('View path') }}</a
                      >
                    </li>
                    <li>
                      <a
                        @click.prevent="OpenPDF(list.id, false)"
                        href=""
                        class="success"
                        >{{ $t('Open PDF') }}</a
                      >
                    </li>
                    <li>
                      <a
                        @click.prevent="DownloadPDF(list, false)"
                        href=""
                        class="success"
                        >{{ $t('Download') }}</a
                      >
                    </li>
                    <li>
                      <a @click.prevent="PrintPDF(list.id, false)" href="">{{
                        $t('Print')
                      }}</a>
                    </li>
                    <li>
                      <a
                        @click.prevent="OpenPDF(list.id, true)"
                        href=""
                        class="success"
                        >{{ $t('PL Open PDF') }}</a
                      >
                    </li>
                    <li>
                      <a
                        @click.prevent="DownloadPDF(list, true)"
                        href=""
                        class="success"
                        >{{ $t('PL Download') }}</a
                      >
                    </li>
                    <li>
                      <a @click.prevent="PrintPDF(list.id, true)" href="">{{
                        $t('PL Print')
                      }}</a>
                    </li>
                  </ul>
                </a>
              </td>
            </tr>
          </tbody>
        </table>
        <div class="grid-pagination">
          <div class="page-navigation">
            <div
              class="page-turn prev disabled"
              @click="PageController(false)"
            ></div>
            <div class="page-number">
              <label class="page-number-current">
                <input
                  type="number"
                  min="1"
                  :max="page.last"
                  :value="page.current"
                  @blur="PageNavigator"
                  @keydown="PageNavigator"
                />
                <span class="placeholder">{{
                  NumberFormat(page.current)
                }}</span>
              </label>
              <span class="page-number-separator">/</span>
              <div class="page-number-last">{{ NumberFormat(page.last) }}</div>
            </div>
            <div
              class="page-turn next"
              :class="{ disabled: page.last == 1 }"
              @click="PageController(true)"
            ></div>
          </div>
          <!--
          <a class="arrow prev" @click.prevent="PageController(false)" href=""><span>&#8249;</span></a>
          <span class="current-page">{{ NumberFormat(page.current) }} / {{ NumberFormat(page.last) }}</span>
          <a class="arrow next" @click.prevent="PageController(true)" href=""><span>&#8250;</span></a>
          -->
        </div>
      </div>
    </div>

    <Modal
      modal="create"
      :value="modal.create.open"
      :title="$t('New pick list')"
    >
      <div id="create-list" class="create-list">
        <div class="search-form">
          <div class="form-elements">
            <div class="input query">
              <label>
                {{ $t('Search') }}
                <input
                  type="text"
                  name="query"
                  class="input query"
                  @keypress.enter="SearchOrders"
                />
              </label>
            </div>
            <div
              class="select company"
              style="width: 100%; display: flex; align-items: flex-end"
            >
              <label style="width: 200px; flex-shrink: 0">
                {{ $t('Company') }}
                <v-select
                  name="company"
                  @input="SearchOrders"
                  :value="modal.create.company.selected"
                  v-model="modal.create.company.selected"
                  :options="modal.create.company.options"
                  :clearable="false"
                />
              </label>
              <div
                class="checkbox-list-label"
                style="width: 100%; margin-left: 30px"
              >
                {{ $t('Delivery company') }}
                <div class="input courier">
                  <ul class="checkbox-list" style="padding: 1px 10px">
                    <li
                      class="checkbox-item"
                      :key="courier.code"
                      v-for="courier in modal.create.couriers"
                    >
                      <label
                        class="checkbox label"
                        :for="courier.code"
                        style="flex-direction: row; align-items: center"
                      >
                        <div class="v-wrapper checkbox" style="min-height: 0px">
                          <div class="v-checkbox-label" style="min-height: 0px">
                            <input
                              class="v-checkbox"
                              type="checkbox"
                              :id="courier.code"
                              :name="courier.code"
                              @input="SearchOrders"
                            />
                            <span
                              class="v-checkbox-toggle"
                              style="width: 18px; height: 18px"
                            />
                          </div>
                        </div>
                        <span
                          :class="['agent-icon', courier.code]"
                          :title="courier.label"
                          style="margin: 0 0 0 6px"
                        />
                        <small class="count">{{
                          NumberFormat(
                            modal.create.available[courier.code] || 0
                          )
                        }}</small>
                      </label>
                    </li>
                  </ul>
                </div>
              </div>
              <div
                class="courier-actions"
                style="margin-left: 30px; flex-shrink: 0"
              >
                <a
                  class="button action-toggle dropdown"
                  style="min-width: 115px"
                  @click.prevent="
                    config.UsePaperlessOrders
                      ? AutoCreateListForEachCourier('create')
                      : $event.target.classList.toggle('visible')
                  "
                  href=""
                >
                  {{ $t('Create all') }}
                  <ul class="button-actions" v-if="!config.UsePaperlessOrders">
                    <li>
                      <a
                        @click.prevent="
                          AutoCreateListForEachCourier('download'),
                            $event.target
                              .closest('.action-toggle')
                              .classList.remove('visible')
                        "
                        href=""
                        >{{ $t('Download') }}</a
                      >
                    </li>
                    <li>
                      <a
                        @click.prevent="
                          AutoCreateListForEachCourier('print'),
                            $event.target
                              .closest('.action-toggle')
                              .classList.remove('visible')
                        "
                        href=""
                        >{{ $t('Print') }}</a
                      >
                    </li>
                  </ul>
                  <span
                    class="button-icon caret-down"
                    v-if="!config.UsePaperlessOrders"
                  />
                </a>
              </div>
            </div>
            <div style="display: flex">
              <label style="width: 200px; flex-shrink: 0">
                {{ $t('Language') }}
                <v-select
                  name="language"
                  @input="FilterByLanguage"
                  :value="
                    (
                      modal.create.languages[modal.create.search.company] || []
                    ).find(
                      (option) => option.code == modal.create.filter.language
                    )
                  "
                  :options="
                    Alphabetize(
                      (
                        modal.create.languages[modal.create.search.company] ||
                        []
                      ).map((option) => {
                        option.label = `${LanguageName(option.language)}`;
                        return option;
                      }),
                      'label'
                    )
                  "
                >
                  <template v-slot:selected-option="option">
                    <div class="truncate">
                      <Flag
                        :code="option.code"
                        size="small"
                        type="language"
                        :title="CountryName(option.country)"
                      />
                      <span>{{ option.label }}</span>
                    </div>
                  </template>
                  <template slot="option" slot-scope="option">
                    <div class="truncate">
                      <Flag
                        :code="option.code"
                        size="small"
                        type="language"
                        :title="CountryName(option.country)"
                      />
                      <span>{{ option.label }}</span>
                    </div>
                  </template>
                </v-select>
              </label>
              <label style="width: 600px; flex-shrink: 0; margin-left: 30px">
                {{ $t('Shipping method') }}
                <v-select
                  name="methods"
                  @input="FilterByMethod"
                  :value="
                    (
                      modal.create.methods[modal.create.search.company] || []
                    ).find(
                      (option) => option.label == modal.create.filter.method
                    )
                  "
                  :options="modal.create.methods[modal.create.search.company]"
                >
                  <template v-slot:selected-option="option">
                    <div class="truncate" :title="option.label">
                      <span :class="['courier', option.code]">
                        {{ option.label }}
                      </span>
                    </div>
                  </template>
                  <template slot="option" slot-scope="option">
                    <div class="truncate" :title="option.label">
                      <span :class="['courier', option.code]">
                        {{ option.label }}
                      </span>
                    </div>
                  </template>
                </v-select>
              </label>
              <label style="display: flex; flex-direction: column; margin-left: 30px;">
                {{ $t('Quantity') }}
                <input
                  class="quantity-input" 
                  type="number"
                  min="0"
                  v-model="modal.create.qty"
                  @change="FilterByQuantity">
              </label>
            </div>
          </div>
        </div>
        <div class="search-result">
          <div class="list-wrapper">
            <div class="list-body">
              <div class="list-head">
                <ul class="list-label">
                  <li class="list-row">
                    <div
                      class="list-col"
                      style="
                        display: flex;
                        flex-direction: row;
                        align-items: center;
                      "
                    >
                      <div class="label">
                        <div class="v-wrapper checkbox" style="min-height: 0px">
                          <label
                            class="v-checkbox-label"
                            style="min-height: 0px"
                          >
                            <input
                              class="v-checkbox"
                              type="checkbox"
                              name="list-toggle-all"
                              :checked="modal.create.list.all"
                              v-model="modal.create.list.all"
                              @change="ToggleAllOrders"
                              :disabled="!modal.create.list.orders.length"
                            />
                            <span
                              class="v-checkbox-toggle"
                              style="width: 18px; height: 18px"
                            />
                          </label>
                        </div>
                      </div>
                      <span style="margin-left: 10px">{{
                        $t('Order number')
                      }}</span>
                    </div>
                    <div class="list-col">{{ $t('Company') }}</div>
                    <div class="list-col">{{ $t('Webshop') }}</div>
                    <div class="list-col">{{ $t('Order date') }}</div>
                    <div class="list-col">{{ $t('Shipping method') }}</div>
                    <div class="list-col" style="text-align: center;">{{$t('Grand total')}}</div>
                    <div class="list-col">{{  $t('Quantity')  }}</div>
                  </li>
                </ul>
              </div>
              <ul class="list-content odd-even">
                <li
                  class="list-row"
                  :key="order.id"
                  v-for="order in modal.create.list.orders"
                >
                  <div
                    class="list-col"
                    style="
                      display: flex;
                      flex-direction: row;
                      align-items: center;
                    "
                  >
                    <div class="label">
                      <div class="v-wrapper checkbox" style="min-height: 0px">
                        <label class="v-checkbox-label" style="min-height: 0px">
                          <input
                            class="v-checkbox"
                            type="checkbox"
                            :id="order.id"
                            :name="order.id"
                            :checked="order.checked"
                            v-model="order.checked"
                            @change="ToggleOrder"
                          />
                          <span
                            class="v-checkbox-toggle"
                            style="width: 18px; height: 18px"
                          />
                        </label>
                      </div>
                    </div>
                    <span
                      style="margin-left: 10px"
                      v-html="
                        Hyperlink({
                          href: ParcelOrder(order.id),
                          target: '_blank',
                          text: order.company_id + ':' + order.increment_id,
                        })
                      "
                    />
                  </div>
                  <div class="list-col">{{ order.company_name }}</div>
                  <div class="list-col">
                    <div style="display: flex; align-items: center">
                      <Flag
                        :code="order.webshop.country"
                        size="small"
                        :title="LanguageName(order.webshop.language)"
                      />
                      <span
                        style="margin-left: 10px"
                        v-html="
                          Hyperlink({
                            href: order.webshop.url,
                            target: '_blank',
                            text: order.webshop.url.replace('https://', ''),
                            title: LanguageName(order.webshop.language),
                          })
                        "
                      />
                    </div>
                  </div>
                  <div class="list-col">{{ DateFormat(order.order_date) }}</div>
                  <div class="list-col trackingnumber">
                    <div class="shipping">
                      <span
                        :class="['courier-icon', order.agent_code]"
                        :title="
                          (
                            modal.create.couriers.find(
                              (option) => option.code == order.agent_code
                            ) || {}
                          ).label
                        "
                      />
                      <span class="shipping-method">{{
                        order.shipping_description
                      }}</span>
                    </div>
                  </div>
                  <div class="list-col" style="max-width: 105px; overflow-wrap: anywhere;"><span style="font-size: 0.5rem">{{ order.currency_iso }}</span> <br> {{ CurrencyFormat(order.grand_total || '0.00', 2) }}</div>
                  <div class="list-col">{{ GetItemQty(order) }}</div>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div class="footer-content">
          <div class="entries">
            {{ NumberFormat(modal.create.list.selected) }} /
            {{ NumberFormat(modal.create.list.entries) }}
          </div>
          <div class="select path strategy">
            <label>
              <span>{{ $t('Path strategy') }}</span>
              <v-select
                name="strategy"
                class="drop-up"
                v-model="modal.create.strategy.selected"
                :options="modal.create.strategy.options"
                append-to-body
                :calculate-position="withPopper"
                :clearable="false"
              />
            </label>
          </div>
          <div class="input wagon amount">
            <label>
              <span>{{ $t('Wagon amount') }}</span>
              <input
                class="input"
                type="number"
                min="1"
                :value="modal.create.wagon.amount"
                @change="SetWagonAmount"
                @keydown.enter="$event.target.blur()"
              />
            </label>
          </div>
          <div class="input wagon size" style="margin-left: 0">
            <label>
              <span>{{ $t('Wagon size') }}</span>
              <input
                class="input"
                type="number"
                min="1"
                :value="modal.create.wagon.size"
                @change="SetWagonSize"
                @keydown.enter="$event.target.blur()"
              />
            </label>
          </div>
          <a
            class="button action-toggle"
            @click="
              !/^v-/.test($event.target.className) &&
                ($event.preventDefault(),
                config.UsePaperlessOrders
                  ? CreateListSubmit($event, 'create')
                  : $event.target.classList.toggle('visible'))
            "
            href=""
          >
            <div
              class="label"
              style="margin-right: 11px"
              :title="$t('Preview path')"
            >
              <div class="v-wrapper checkbox" style="min-height: 0px">
                <label class="v-checkbox-label" style="min-height: 0px">
                  <input
                    class="v-checkbox"
                    type="checkbox"
                    v-model="modal.create.strategy.preview.checked"
                  />
                  <span
                    class="v-checkbox-toggle"
                    style="width: 18px; height: 18px"
                  >
                    <span class="v-checkbox-toggle-icon route uncheck-gray" />
                  </span>
                </label>
              </div>
            </div>
            {{ $t('Create') }}
            <ul class="button-actions" v-if="!config.UsePaperlessOrders">
              <li>
                <a
                  @click.prevent="
                    CreateListSubmit($event, 'download'),
                      $event.target
                        .closest('.action-toggle')
                        .classList.remove('visible')
                  "
                  href=""
                  >{{ $t('Download') }}</a
                >
              </li>
              <li>
                <a
                  @click.prevent="
                    CreateListSubmit($event, 'print'),
                      $event.target
                        .closest('.action-toggle')
                        .classList.remove('visible')
                  "
                  href=""
                  >{{ $t('Print') }}</a
                >
              </li>
            </ul>
            <span
              class="button-icon caret-up"
              v-if="!config.UsePaperlessOrders"
            />
          </a>
        </div>
      </div>
      <div class="loading" v-if="modal.create.loading">
        <div class="loading-element">
          <span></span>
          <span></span>
          <span></span>
          <span></span>
        </div>
      </div>
    </Modal>

    <Modal modal="view" :value="modal.view.open" :title="$t('Pick list')">
      <div id="view-list" class="view-list" style="padding-top: 0">
        <!-- <div class="modal-header"></div> -->
        <div class="modal-content scrollable">
          <div class="information">
            <span class="info-primary">
              <p class="info-row">
                {{ $t('List ID') }}: <span>{{ modal.view.list.id }}</span>
              </p>
              <p class="info-row">
                {{ $t('Created') }}:
                <span>{{ DateFormat(modal.view.list.create_date) }}</span>
              </p>
              <p class="info-row">
                {{ $t('Created by') }}:
                <span>{{ modal.view.list.create_by }}</span>
              </p>
            </span>
            <span class="info-secondary">
              <p class="info-row">
                {{ $t('Done') }}:
                <span
                  class="state"
                  :class="[modal.view.list.is_done ? 'positive' : 'negative']"
                  >{{ $t(modal.view.list.is_done ? 'Yes' : 'No') }}</span
                >
              </p>
              <p class="info-row">
                {{ $t('Orders') }}:
                <span>{{ NumberFormat(modal.view.list.item_count) }}</span>
              </p>
              <p class="info-row">
                {{ $t('Wagon size') }}:
                <span>{{ NumberFormat(modal.view.list.wagon_size) }}</span>
              </p>
            </span>
          </div>
          <div class="list-view">
            <div class="list-wrapper">
              <div class="list-body">
                <div class="list-head">
                  <ul class="list-label">
                    <li class="list-row">
                      <div class="list-col">{{ $t('Order number') }}</div>
                      <div class="list-col">{{ $t('Bypass Order') }}</div>
                      <div class="list-col">{{ $t('Company') }}</div>
                      <div class="list-col">{{ $t('Webshop') }}</div>
                      <div class="list-col">{{ $t('Order date') }}</div>
                      <div class="list-col">{{ $t('Shipping method') }}</div>
                    </li>
                  </ul>
                </div>
                <ul class="list-content odd-even">
                  <li
                    class="list-row"
                    :key="item.id"
                    v-for="item in modal.view.list.items"
                  >
                    <!-- <div class="list-col">{{ item.company_id }}:{{ item.increment_id }}</div> -->
                    <div
                      class="list-col"
                      v-html="
                        Hyperlink({
                          href: ParcelOrder(item.id),
                          target: '_blank',
                          text: item.company_id + ':' + item.increment_id,
                        })
                      "
                    />
                    <div class="list-col">
                      <input
                        class="bypass-checkbox"
                        type="checkbox"
                        v-model="item.bypass"
                      />
                    </div>
                    <div class="list-col">
                      {{ GetCompanyById(item.company_id) }}
                    </div>
                    <div class="list-col">
                      <div style="display: flex; align-items: center">
                        <Flag
                          :code="item.webshop.country"
                          size="small"
                          :title="LanguageName(item.webshop.language)"
                        />
                        <span
                          style="margin-left: 10px"
                          v-html="
                            Hyperlink({
                              href: item.webshop.url,
                              target: '_blank',
                              text: item.webshop.url.replace('https://', ''),
                              title: LanguageName(item.webshop.language),
                            })
                          "
                        />
                      </div>
                    </div>
                    <div class="list-col">
                      {{ DateFormat(item.order_date) }}
                    </div>
                    <div class="list-col trackingnumber">
                      <div class="shipping">
                        <span
                          :class="['courier-icon', item.agent_code]"
                          :title="item.agent_name"
                        />
                        <span class="shipping-method">{{ item.shipping }}</span>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <div class="button-container actions">
            <button
              class="button green"
              @click.prevent="ViewPickPath(modal.view.list)"
            >
              {{ $t('View path') }}
            </button>
            <button
              class="button green"
              @click.prevent="OpenPDF(modal.view.list.id, false)"
            >
              {{ $t('Open PDF') }}
            </button>
            <button
              class="button green"
              @click.prevent="DownloadPDF(modal.view.list, false)"
            >
              {{ $t('Download') }}
            </button>
            <div
              class="button action-toggle"
              @click.prevent="$event.target.classList.toggle('visible')"
              v-if="modal.view.open && permission.admin"
            >
              {{ $t('View map') }}
              <ul class="button-actions">
                <li
                  v-for="key in Object.keys(
                    modal.view.list.order_by_data.sort.wagon
                  )"
                  :key="key"
                >
                  <a
                    @click.prevent="
                      $event.target
                        .closest('.action-toggle')
                        .classList.remove('visible'),
                        GenerateWarehouseMap(modal.view.list.id, key)
                    "
                    >{{ $t('View wagon') }} {{ key }} {{ $t('Map') }}</a
                  >
                </li>
              </ul>
              <span class="button-icon caret-up" />
            </div>
            <div
              class="button action-toggle"
              @click.prevent="$event.target.classList.toggle('visible')"
              v-if="modal.view.open"
            >
              {{ $t('Release Paperless') }}
              <ul class="button-actions">
                <li
                  v-for="key in Object.keys(
                    modal.view.list.order_by_data.sort.wagon
                  )"
                  :key="key"
                >
                  <a
                    @click.prevent="
                      $event.target
                        .closest('.action-toggle')
                        .classList.remove('visible'),
                        ReleasePaperless(modal.view.list.id, key)
                    "
                    >Release Wagon {{ key }}</a
                  >
                </li>
              </ul>
              <span class="button-icon caret-up" />
            </div>
            <div
              class="button action-toggle"
              @click.prevent="$event.target.classList.toggle('visible')"
              v-if="modal.view.open"
            >
              Bypass packing order
              <ul class="button-actions">
                <li>
                  <a
                    @click.prevent="
                      $event.target
                        .closest('.action-toggle')
                        .classList.remove('visible'),
                        BypassUsedInPacking(modal.view.list.items, true)
                    "
                    >Bypass ALL orders</a
                  >
                </li>
                <li>
                  <a
                    @click.prevent="
                      $event.target
                        .closest('.action-toggle')
                        .classList.remove('visible'),
                        BypassUsedInPacking(modal.view.list.items, false)
                    "
                    >Bypass selected orders</a
                  >
                </li>
              </ul>
              <span class="button-icon caret-up" />
            </div>
            <button
              class="button blue"
              @click.prevent="PrintPDF(modal.view.list.id, false)"
            >
              {{ $t('Print') }}
            </button>
          </div>
        </div>
      </div>
    </Modal>

    <div class="loading" v-if="loading">
      <div class="loading-element">
        <span></span>
        <span></span>
        <span></span>
        <span></span>
      </div>
    </div>
  </div>
</template>

<script>
import { FormElementsBehaviour } from '@/mixins/FormElementsBehaviour';
import { TableElementsBehaviour } from '@/mixins/TableElementsBehaviour';
import { BPA } from '@/helpers/BPA';
import { Tool } from '@/helpers/Tool';
import { createPopper } from '@popperjs/core';
import Modal from '@/components/snippets/Modal';
import Flag from '@/components/snippets/Flag';

export default {
  name: 'ListsPickLists',
  mixins: [BPA, FormElementsBehaviour, TableElementsBehaviour],
  components: {
    Modal,
    Flag,
  },
  data() {
    return {
      loading: false,
      entries: 0,
      cached: {},
      clickables: [],
      lists: [],
      list: {},
      page: {
        current: 1,
        number: 1,
        last: 1,
        order: {
          direction: 'desc',
          by: 'id',
        },
      },
      search: {
        query: '',
      },
      config: {},
      shipping: {},
      languages: {},
      methods: {},
      modal: {
        create: {
          open: false,
          loading: false,
          companies: {},
          couriers: [],
          available: {},
          languages: {},
          methods: {},
          qty: {},
          orders: [],
          company: {
            default: '',
            selected: '',
            options: [],
          },
          filter: {
            language: '',
            method: '',
            qty: 0,
          },
          search: {
            query: '',
            company: '',
            courier: '',
            all: '',
          },
          list: {
            orders: [],
            entries: 0,
            selected: 0,
            all: '',
          },
          strategy: {
            preview: {
              data: {},
              request: '',
              checked: true,
            },
            placement: 'top',
            selected: '',
            options: [
              { code: 'lkh', label: 'Lin-Kernighan heuristic' },
              { code: 'nts', label: 'Nearest to start' },
              { code: 'nn', label: 'Nearest neighbor' },
              { code: 'na', label: 'Numeric ascending' },
            ],
          },
          wagon: {
            default: 45,
            amount: 1,
            size: 45,
          },
        },
        view: {
          open: false,
          loading: false,
          companies: {},
          list: {},
          preview: {
            data: {},
          },
        },
      },
      permission: {
        admin: false,
      },
    };
  },
  created() {
    this.create = {
      node: {},
      nodes: [
        'table',
        'thead',
        'tbody',
        'th',
        'tr',
        'td',
        'button',
        'span',
        'div',
        'h4',
        'p',
      ],
    };
    for (let node of this.create.nodes) {
      this.create.node[node] = document.createElement(node);
      this.create[node] = (props = {}) => {
        let elm = this.create.node[node].cloneNode();
        for (let key in props) {
          elm[key] = props[key];
        }
        return elm;
      };
    }
  },
  async mounted() {
    this.permission.admin = this.Check([/*0, 1*/ 'admin']);
    let couriers = BPA.api.Couriers('GET');
    this.$eventHub.$on('CloseModal', (modal_name) => {
      if (this.modal[modal_name]) this.modal[modal_name].open = false;
      if (modal_name == 'view') {
        this.list = {};
        this.cached.list = {};
        BPA.cache.session({ name: this.$options.name, set: { list: {} } });
        for (let i = 0; i < this.clickables.length; i++) {
          if (this.clickables[i].classList.contains('selected')) {
            this.clickables[i].classList.remove('selected');
          }
        }
      }
    });
    BPA.api.Companies('GET').forEach((company) => {
      this.modal.create.companies[company.code] = company.name;
      this.modal.view.companies[company.id] = company.name;

      if (company.code == BPA.util.GetCompany()) {
        let selection = { code: company.code, label: company.name };
        this.modal.create.company.default = selection;
        this.modal.create.company.selected = selection;
      }

      this.modal.create.company.options.push({
        code: company.code,
        label: company.name,
      });
    });

    for (let code in couriers) {
      this.modal.create.couriers.push({ code: code, label: couriers[code] });
    }
    this.modal.create.couriers.sort((a, b) => {
      a = a.label.toUpperCase();
      b = b.label.toUpperCase();
      return a < b ? -1 : a > b ? 1 : 0;
    });

    this.cached = BPA.cache.local({
      name: this.$options.name,
      get: ['page', 'search'],
    });
    for (let key of Object.keys(this.cached))
      this[key] = { ...this[key], ...this.cached[key] };
    this.cached = {
      ...this.cached,
      ...BPA.cache.session({ name: this.$options.name, get: 'list' }),
    };

    await this.GetConfig();

    this.QueryLists();
  },
  methods: {
    withPopper(dropdownList, component, { width }) {
      dropdownList.style.width = width;
      let popper = createPopper(component.$refs.toggle, dropdownList, {
        placement: this.modal.create.strategy.placement,
        modifiers: [
          {
            name: 'offset',
            options: {
              offset: [0, -1],
            },
          },
          {
            name: 'toggleClass',
            phase: 'write',
            enabled: true,
            fn({ state }) {
              component.$el.classList.toggle(
                'drop-up',
                state.placement === 'top'
              );
            },
          },
        ],
      });
      return () => popper.destroy();
    },
    Hyperlink(props = {}) {
      return Tool.Hyperlink(props);
    },
    DateFormat(date) {
      return Tool.DateFormat(date);
    },
    NumberFormat(number) {
      return Tool.NumberFormat(number);
    },
    CountryName(country_code, locale) {
      return Tool.CountryName(country_code, locale);
    },
    LanguageName(country_code, locale) {
      return Tool.LanguageName(country_code, locale);
    },
    CurrencyFormat(number, minmax) {
      return Tool.CurrencyFormat(number, null, minmax);
    },
    Check(required) {
      return BPA.permissions(required).length;
    },
    Alphabetize(list, prop) {
      return Tool.Alphabetize(list, prop);
    },
    Webshop(company_id, increment_id) {
      return Tool.Webshop(company_id, increment_id);
    },
    CloneObject(object = {}) {
      return JSON.parse(JSON.stringify(object));
    },
    Empty(element) {
      while (element.firstChild) {
        element.removeChild(element.firstChild);
      }
    },
    ParcelOrder(order_id = '') {
      return window.location.origin + '/parcels/orders?id=' + order_id;
    },
    GetCompanyById(company_id) {
      return this.modal.view.companies[company_id];
    },
    GetItemQty(order) {
      let qty = 0
      for(let i = 0; i < order.products.length; i++) {
        qty += order.products[i].qty;
      }
      return qty;
    },
    Search(event) {
      let input = event.target;
      let value = input.value.replace(/\s+/g, ' ').trim();
      this.search.query = value;
      this.page.current = 1;
      input.value = value;
      this.QueryLists();
      input.blur();
    },
    async QueryLists() {
      this.loading = true;
      await BPA.api
        .GetLists({
          query: this.search.query,
          order_by: this.page.order.by,
          order_direction: this.page.order.direction,
          page_offset: this.page.current,
        })
        .then((response) => {
          return BPA.api.response({ response, return: 'json' });
        })
        .then((response) => {
          this.loading = false;
          if (!response.ok || !response.result) return;
          let list = response.result || {};
          if (!list.page_offset) list.page_offset = 0;
          this.page.current = list.page_offset + 1;
          this.page.last = list.total_pages || 1;
          this.entries = list.items_total;
          this.lists = list.items;
          BPA.cache.local({
            name: this.$options.name,
            set: { page: this.page, search: this.search },
          });
          this.$nextTick().then(() => {
            this.clickables = document.querySelectorAll('.clickable');
            if (this.cached.list && Object.keys(this.cached.list).length) {
              this.ViewListShow(this.cached.list.id);
            }
            this.SetPageJumpWidth();
          });
        });
    },
    PageController(next_page) {
      let load_page;
      const page = {};
      document.querySelectorAll('.page-turn').forEach((arrow) => {
        if (arrow)
          page[arrow.classList.contains('prev') ? 'prev' : 'next'] = arrow;
      });
      if (next_page) {
        if (this.page.current != this.page.last) {
          this.page.current++;
          load_page = true;
          if (this.page.current == this.page.last) {
            page.next.classList.add('disabled');
          }
          page.prev.classList.remove('disabled');
        }
      } else {
        if (this.page.current > 1) {
          this.page.current--;
          load_page = true;
          if (this.page.current == 1) {
            page.prev.classList.add('disabled');
          }
          page.next.classList.remove('disabled');
        }
      }
      if (load_page) {
        this.QueryLists();
      }
    },
    PageNavigator(e) {
      const page = {
        event: e.type,
        input: e.target,
        last: Number(e.target.max),
        first: Number(e.target.min),
        number: Number(e.target.value),
      };
      const within_limits = (n) => {
        return n >= page.first && n <= page.last;
      };
      const set_page_number = (n) => {
        this.page.number = n || page.number;
        page.number = Number(this.page.number);
      };
      document.querySelectorAll('.page-turn').forEach((arrow) => {
        if (arrow)
          page[arrow.classList.contains('prev') ? 'prev' : 'next'] = arrow;
      });
      set_page_number();
      if (page.event == 'keydown') {
        if (e.key == 'Enter') {
          page.input.blur();
        }
      }
      if (page.event == 'blur') {
        ['prev', 'next'].map((arrow) => {
          page[arrow] && page[arrow].classList.remove('disabled');
        });
        if (page.number <= page.first) {
          set_page_number(page.first);
          page.input.value = page.first;
          page.prev.classList.add('disabled');
          if (page.last == page.first) {
            page.next.classList.add('disabled');
          }
        } else if (page.number >= page.last) {
          set_page_number(page.last);
          page.input.value = page.last;
          page.next.classList.add('disabled');
          if (page.first == page.last) {
            page.prev.classList.add('disabled');
          }
        }
        if (within_limits(page.number) && page.number != this.page.current) {
          this.page.current = page.number;
          this.QueryLists();
        }
      }
    },
    SetPageJumpWidth() {
      const current = document.querySelector('.page-number-current');
      const last = document.querySelector('.page-number-last');
      if (current && last) {
        const rect = last.getBoundingClientRect();
        const input = current.querySelector('input');
        if (rect && rect.width) {
          current.style.setProperty('width', rect.width + 'px');
        } else {
          current.style.removeProperty('width');
        }
        if (input) {
          input.dispatchEvent(new Event('blur'));
        }
      }
    },
    async CreateListShow() {
      let create = this.modal.create;
      let strategy = create.strategy;
      let wagon = create.wagon;
      create.company.selected = create.company.default;
      strategy.selected = strategy.options.find(
        (option) => option.code == 'lkh'
      );
      strategy.preview.checked = true;
      wagon.size = wagon.default;
      create.loading = false;
      wagon.amount = 1;
      create.available = {};
      create.languages = {};
      create.methods = {};
      create.qty = {};
      create.orders = [];
      create.filter = {
        language: '',
        method: '',
        qty: 0,
      };
      create.search = {
        query: '',
        company: this.GetCompanyCode(create.company.selected.label),
        courier: '',
        all: '',
      };
      create.list = {
        orders: [],
        selected: 0,
        entries: 0,
        all: '',
      };
      let storage = BPA.util.storage;
      let languages = storage.get('languages');
      let refetch = Object.keys(languages).length == 1;
      if (!Object.keys(languages).length || refetch) {
        const get_languages = async (company_code) => {
          let locales = await this.GetLanguageOrderCount(company_code);
          let company = company_code || create.company.selected.code;
          languages[company] = [];
          for (let locale in locales) {
            let option = { code: locale };
            if (/nb/.test(locale)) {
              locale = locale.replace('nb', 'no');
            }
            let code = locale.split('_');
            option.label = this.LanguageName(code[0], 'en');
            option.language = code[0];
            option.country = code[1];
            languages[company].push(option);
          }
        };
        for (let company of Object.keys(create.companies)) {
          if (!(company in languages)) {
            await get_languages(company);
          }
        }
        storage.set('languages', languages);
      }
      this.languages = languages;
      await this.GetOrders();
      await this.GetConfig();
      create.open = true;
    },
    async SearchOrders(event) {
      let create = this.modal.create;
      let search = create.search;
      let list = create.list;
      if (event) {
        if (event.target) {
          if (event.target.localName == 'input') {
            if (event.target.name == 'query') {
              console.log(event.target.value);
            }
            if (event.target.type == 'checkbox') {
              if (create.company.selected) {
                search.company = this.GetCompanyCode(
                  create.company.selected.label
                );
                if (!this.methods[search.company]) {
                  this.methods[search.company] = await this.GetShippingMethods(
                    search.company
                  );
                }
              } else {
                create.company.selected = '';
              }
              search.courier = '';
              document
                .querySelectorAll('.courier input[type=checkbox]')
                .forEach((checkbox) => {
                  if (
                    create.couriers.find(
                      (courier) => courier.code == checkbox.name
                    )
                  ) {
                    if (event.target != checkbox) {
                      //checkbox.checked = false;
                    }
                    if (checkbox.checked) {
                      search.courier += `${checkbox.name},`;
                    }
                  }
                });
              search.courier = search.courier.slice(0, -1);
            }
          }
        } else {
          search.company = event.code;
        }
      } else {
        search.company = '';
        list.selected = 0;
        list.entries = 0;
        list.all = '';
      }
      this.GetOrders();
    },
    async GetOrders() {
      let create = this.modal.create;
      let filter = create.filter;
      let search = create.search;
      let list = create.list;
      for (let prop in search) {
        let value = search[prop];
        let type = typeof value;
        if (prop == 'company') {
          this.UpdateCourierLabels(value);
        }
        if (!/query|all/.test(prop) && type == 'string' && !value.length) {
          create.languages = {};
          create.methods = {};
          create.orders = [];
          filter.language = '';
          filter.method = '';
          filter.qty = 0;
          list.selected = 0;
          list.entries = 0;
          list.orders = [];
          list.all = '';
          return;
        }
      }
      delete search.query;
      delete search.all;
      filter.language = '';
      filter.method = '';
      filter.qty = 0;
      list.orders = [];
      create.languages = {};
      create.methods = {};
      create.orders = [];
      create.loading = true;
      const params = {};
      for (let key in search) {
        let prop = key;
        if (key == 'company') prop = 'company_code';
        if (key == 'courier') prop = 'agent_code';
        params[prop] = search[key];
      }
      await BPA.api
        .SearchOrders(params)
        .then((response) => {
          return BPA.api.response({ response, return: 'json' });
        })
        .then(async (response) => {
          if (!response.ok) {
            return (create.loading = false);
          }
          let items = response.result || [];
          let orders = [];
          let selected = 0;
          let companies = {};
          let languages = { [search.company]: [] };
          let methods = { [search.company]: [] };
          let couriers = create.couriers;
          BPA.api.Companies('GET').map((company) => {
            companies[company.id] = company.name;
          });
          list.selected = items.length;
          list.entries = items.length;
          let wagon = this.modal.create.wagon;
          let total = wagon.amount * wagon.size;
          //total = items.length; // Force select all orders
          items.sort((a, b) => new Date(a.order_date) - new Date(b.order_date));
          items.forEach((order, index) => {
            let checked = index + 1 <= total;
            if (checked) selected++;
            orders.push({
              ...{ checked, company_name: companies[order.company_id] },
              ...order,
            });
          });
          list.all =
            orders.length &&
            orders.filter((order) => order.checked).length == orders.length;
          for (let order of orders) {
            order.agent_code = this.shipping[order.shipping_description];
            let option = couriers.find(
              (option) => option.code == order.agent_code
            );
            order.agent_name = option ? option.label : '';
            order.webshop = this.Webshop(order.company_id, order.increment_id);
            order.webshop.country = order.webshop.country.toUpperCase();
            order.filter = {
              language: `${String(order.webshop.language).replace(
                'no',
                'nb'
              )}_${order.webshop.country}`,
              method: order.shipping_description,
              qty: this.GetItemQty(order),
            };
            if (!order.filter.language.split('_').filter((e) => e).length) {
              order.filter.language = '';
            }
            /*
            if (!order.webshop.language || !order.webshop.country) {
              if (/klubklub/.test(order.webshop.url)) {
                order.webshop.language = 'da';
                order.webshop.country = 'dk';
                order.webshop.url += 'dk';
              }
            }
            */
            let language = (this.languages[search.company] || []).find(
              (option) => option.country == order.webshop.country
            );
            if (
              language &&
              !languages[search.company].some(
                (option) => option.country == language.country
              )
            ) {
              languages[search.company].push(language);
            }
            let method = (this.methods[search.company] || []).find(
              (option) => option.shipping_method == order.shipping_description
            );
            if (
              method &&
              !methods[search.company].some(
                (option) => option.shipping_method == method.shipping_method
              )
            ) {
              method.label = method.shipping_method;
              method.code = method.agent_code;
              methods[search.company].push(method);
            }
          }
          this.Alphabetize(methods[search.company], 'code');
          create.languages = languages;
          create.methods = methods;
          create.orders = orders;
          list.orders = orders;
          list.selected = selected;
          this.$nextTick().then(() => {
            create.loading = false;
          });
        })
        .catch((e) => e);
    },
    FilterByLanguage(option) {
      let language = (option || {}).code || '';
      this.modal.create.filter.language = language;
      this.FilterOrders();
    },
    FilterByMethod(option) {
      let method = (option || {}).label || '';
      this.modal.create.filter.method = method;
      this.FilterOrders();
    },
    FilterByQuantity(option) {
      let qty = option.target._value || 0;
      this.modal.create.filter.qty = qty;
      this.FilterOrders();
    },
    FilterOrders() {
      let delta = 1;
      let selected = 0;
      let create = this.modal.create;
      let list = create.list;
      let wagon = create.wagon;
      let total = wagon.amount * wagon.size;
      let filter = create.filter || {};
      let orders = this.CloneObject(create.orders) || [];
      orders.map((order, index) => {
        order.checked = false;
        let filter_order = false;
        for (let key in filter) {
          if (filter[key] && order.filter[key] != filter[key]) {
            if(key == 'qty') {
              if(filter[key] >= order.filter[key]) {
                filter_order = true;
              }
            }
            else {
              filter_order = true;
            }
          }
        }
        if (filter_order) {
          console.log('filter order here', order)
          orders = orders.filter((item) => item.id != order.id);
          delta--;
        } else {
          let checked = index + delta <= total;
          if (checked) {
            order.checked = true;
            selected++;
          }
        }
      });
      orders.sort((a, b) => new Date(a.order_date) - new Date(b.order_date));
      list.all =
        orders.length &&
        orders.filter((order) => order.checked).length == orders.length;
      list.orders = orders;
      list.selected = selected;
    },
    GetCompanyCode(company_name) {
      return Object.keys(this.modal.create.companies).find(
        (key) => this.modal.create.companies[key] == company_name
      );
    },
    async GetShippingMethods(company_code = BPA.util.GetCompany()) {
      //if (Object.keys(this.shipping).length) return this.shipping;
      return await BPA.api
        .GetShippingOptions(company_code)
        .then((response) => {
          return BPA.api.response({ response, return: 'json' });
        })
        .then((response) => {
          if (!response.ok) {
            this.shipping = {};
            return [];
          }
          let shipping = {};
          let options = response.result || [];
          this.Alphabetize(options, 'agent_code');
          for (let option of options) {
            shipping[option.shipping_method] = option.agent_code;
          }
          this.shipping = shipping;
          return options;
        })
        .catch((e) => e);
    },
    async UpdateCourierLabels(company_code) {
      return await BPA.api
        .GetAvailableOrderCount(company_code)
        .then((response) => {
          return BPA.api.response({ response, return: 'json' });
        })
        .then((response) => {
          if (!response.ok) return;
          let json = response.result || {};
          let available = this.modal.create.available;
          if (!Object.keys(json).length) {
            for (let agent in available) {
              json[agent] = available[agent];
            }
          }
          this.modal.create.available = json;
        })
        .catch((e) => e);
    },
    ToggleAllOrders(event) {
      let checkbox = event.target;
      let checked = checkbox.checked;
      let create = this.modal.create;
      let list = create.list;
      let orders = list.orders;
      for (let i = 0; i < orders.length; i++) {
        orders[i].checked = !!checked;
      }
      list.selected = orders.filter((order) => order.checked).length;
      list.all = list.selected == orders.length;
    },
    ToggleOrder(event) {
      let checkbox = event.target;
      let checked = checkbox.checked;
      let create = this.modal.create;
      let list = create.list;
      let orders = list.orders;
      for (let i = 0; i < orders.length; i++) {
        if (orders[i].id == checkbox.id) {
          orders[i].checked = !!checked;
          break;
        }
      }
      list.selected = orders.filter((order) => order.checked).length;
      list.all = list.selected == orders.length;
    },
    SetWagonAmount(event) {
      let input = event.target;
      let value = input.value;
      let create = this.modal.create;
      let list = create.list;
      let orders = list.orders;
      let wagon = create.wagon;
      wagon.amount = value.trim().length ? Number(value) : 1;
      let wagon_total = wagon.amount * wagon.size;
      input.value = wagon.amount;
      for (let i = 0; i < orders.length; i++) {
        orders[i].checked = i < wagon_total;
      }
      list.selected = orders.filter((order) => order.checked).length;
      list.all = list.selected == orders.length;
      this.FilterOrders();
    },
    SetWagonSize(event) {
      let input = event.target;
      let value = input.value;
      let create = this.modal.create;
      let list = create.list;
      let orders = list.orders;
      let wagon = create.wagon;
      wagon.size = value.trim().length ? Number(value) : 1;
      let wagon_total = wagon.amount * wagon.size;
      input.value = wagon.size;
      for (let i = 0; i < orders.length; i++) {
        orders[i].checked = i < wagon_total;
      }
      list.selected = orders.filter((order) => order.checked).length;
      list.all = list.selected == orders.length;
      this.FilterOrders();
    },
    AutoCreateListForEachCourier(action) {
      const lists = { total: 0, created: 0, error: false };
      const company_code = this.modal.create.company.selected.code;
      const wagon_size = this.modal.create.wagon.size;
      const strategy = String(
        this.modal.create.strategy.selected.code
      ).toUpperCase();
      const printer_id = (BPA.printer.fetch('a4') || {}).id;
      if (!this.modal.create.company.selected) {
        return this.$eventHub.$emit('ShowMessages', {
          message: 'No company selected',
          type: 'error',
          hide: 2000,
        });
      }
      if (!this.modal.create.wagon.size) {
        return this.$eventHub.$emit('ShowMessages', {
          message: 'Wagon size is missing',
          type: 'error',
          hide: 2000,
        });
      }
      if (action == 'print' && !printer_id) {
        return this.$eventHub.$emit('ShowMessages', {
          message: 'A4 printer is not selected',
          type: 'error',
          hide: 2000,
        });
      }
      this.$eventHub.$emit('ValidateModalStart', {
        approve: `Yes, ${action} lists`,
        disapprove: 'No',
        message:
          'This action cannot be undone!\n\nThe process may take a while and navigating away\nfrom this view could cause issues... so please dont.\n\nAre you sure that you want to continue?',
        type: 'success',
      });
      this.$eventHub.$on('ValidateModalStop', (approve) => {
        this.$eventHub.$off('ValidateModalStop');
        if (!approve) return;
        this.loading = true;
        BPA.api
          .GetAvailableOrderCount(company_code)
          .then((response) => {
            return BPA.api.response({ response, return: 'json' });
          })
          .then((response) => {
            if (!response.ok) return;
            let count = response.result || {};
            let couriers = Object.keys(count).filter(
              (courier) => count[courier]
            );
            if (!couriers.length) {
              this.loading = false;
              return this.$eventHub.$emit('ShowMessages', {
                message: 'No orders available',
                type: 'error',
                hide: 2000,
              });
            }
            lists.total = couriers.length;
            couriers.forEach((courier_code) => {
              if (lists.error) {
                return (this.loading = false);
              }
              BPA.api
                .SearchOrders({
                  company_code: company_code,
                  agent_code: courier_code,
                })
                .then((response) => {
                  return BPA.api.response({ response, return: 'json' });
                })
                .then((response) => {
                  if (!response.ok || !response.result || lists.error) {
                    return (this.loading = false);
                  }
                  let orders = response.result || [];
                  let request = { strategy, wagon_size, items: [] };
                  orders.forEach((order) => request.items.push(order.id));
                  BPA.api
                    .CreatePicklist(request)
                    .then((response) => {
                      return BPA.api.response({ response, return: 'text' });
                    })
                    .then((response) => {
                      if (!response.ok || !response.result || lists.error) {
                        return (this.loading = false);
                      }
                      BPA.api
                        .PrintPicklist({
                          list_id: response.result,
                          printer_id: printer_id,
                        })
                        .then((response) => {
                          return BPA.api.response({ response });
                        })
                        .then((response) => {
                          if (!response.ok) return;
                          lists.created++;
                          if (lists.created != lists.total) return;
                          this.loading = false;
                          this.$eventHub.$emit('ShowMessages', {
                            message:
                              'All pick lists successfully created and sent to A4 printer',
                            type: 'success',
                            hide: 2000,
                          });
                          this.QueryLists();
                          this.$eventHub.$emit('CloseModal', 'create');
                        })
                        .catch(() => {
                          this.loading = false;
                          lists.error = true;
                        });
                    })
                    .catch(() => {
                      this.loading = false;
                      lists.error = true;
                    });
                })
                .catch(() => {
                  this.loading = false;
                  lists.error = true;
                });
            });
          })
          .catch(() => (this.loading = false));
      });
    },
    async CreateListSubmit(e, action) {
      if (e.target.closest('.checkbox')) return;
      const request = { wagon_size: 0, items: [] };
      const strategy = this.modal.create.strategy;
      const printer_id = (BPA.printer.fetch('a4') || {}).id;
      const list = document.getElementById('create-list');
      const wagon = list.querySelector('.input.wagon.size input[type=number]');
      const orders = [
        ...list.querySelectorAll('.list-content input[type=checkbox]:checked'),
      ];
      if (!orders.length) {
        return this.$eventHub.$emit('ShowMessages', {
          message: 'No orders selected',
          type: 'error',
          hide: 2000,
        });
      }
      if (Number(wagon.value) <= 0) {
        return this.$eventHub.$emit('ShowMessages', {
          message: 'Wagon size is missing',
          type: 'error',
          hide: 2000,
        });
      }
      if (action == 'print' && !printer_id) {
        return this.$eventHub.$emit('ShowMessages', {
          message: 'A4 printer is not selected',
          type: 'error',
          hide: 2000,
        });
      }
      request.wagon_size = Number(wagon.value);
      request.items = orders.map((order) => Number(order.id));
      request.strategy = String(strategy.selected.code).toUpperCase();
      let validate_modal = {
        approve: `Yes, ${action} list`,
        disapprove: 'No',
        message: `Creates pick list for <b>${request.items.length}</b> order${
          request.items.length != 1 ? 's' : ''
        }.`,
        type: 'success',
      };
      if (strategy.preview.checked) {
        const create = this.create;
        let overlay = create.div();
        validate_modal.esc = false;
        Object.assign(overlay.style, {
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          zIndex: 10,
          position: 'fixed',
        });
        document.body.append(overlay);
        this.$eventHub.$emit('ValidateModalStart', validate_modal);
        this.$nextTick().then(async () => {
          let popup = document.querySelector('.validate-popup__content');
          let titles = popup.querySelector('.titles');
          let subtitle = titles.querySelector('.subtitle');
          let paragraph = create.p({ innerHTML: validate_modal.message });
          let path_strategy = create.p({
            innerHTML: `Path strategy: <b>${strategy.selected.label}</b>`,
            style: 'margin-top: 16px;',
          });
          let container = create.div({ className: 'path-preview-container' });
          let generating = create.span({
            className: 'generating',
            textContent: 'Loading path preview',
          });
          this.Empty(subtitle);
          container.append(generating);
          subtitle.append(paragraph, path_strategy, container);
          if (await this.CreatePicklistPathPreview(request)) {
            let preview = this.CloneObject(strategy.preview.data);
            //console.log(preview)
            let button = {};
            let table = create.table({
              className: 'list table odd-even',
              style: 'border-bottom: 2px solid lightgrey;',
            });
            let thead = create.thead();
            let tbody = create.tbody();
            let tr = create.tr();
            let orders = preview.orders;
            let products = preview.products;
            let path_data = preview.path_data;
            let sum = {
              div: create.div({ className: 'sum' }),
              heading: create.h4({ textContent: 'Summation' }),
              list: { div: create.div({ className: 'sum-list' }) },
            };
            let headings = {
              sum: [
                'SKU',
                'Product',
                'Quantity',
                'Aisle',
                'Rack',
                'Shelf',
                'Order number',
                'Wagon',
              ],
              path: ['Aisle', 'Rack', 'Shelf', 'SKU', 'Product'],
            };
            Object.assign(sum.heading.style, {
              top: 0,
              zIndex: 3,
              position: 'sticky',
              background: 'white',
            });
            Object.assign(popup.style, {
              maxWidth: '100%',
              maxHeight: '100%',
              display: 'flex',
              flexDirection: 'column',
            });
            Object.assign(titles.style, {
              display: 'flex',
              flexDirection: 'column',
              overflow: 'hidden',
            });
            Object.assign(subtitle.style, {
              display: 'flex',
              flexDirection: 'column',
              overflow: 'hidden',
            });
            Object.assign(container.style, {
              marginTop: '20px',
              overflow: 'hidden auto',
              whiteSpace: 'initial',
            });
            for (let heading of headings.sum) {
              tr.append(
                create.th({ textContent: heading, style: 'top: 23.19px' })
              );
            }
            for (let btn of document.querySelectorAll('a[class^=btn]')) {
              button[/success/.test(btn.className) ? 'confirm' : 'cancel'] =
                btn;
              btn.style.minWidth = '150px';
            }
            path_data.pos.shift();
            let path = { wagons: [] };
            for (let number in path_data.wagon) {
              let wagon = { number, list: [] };
              for (let sku of path_data.wagon[number]) {
                wagon.list.push(products[sku]);
              }
              path.wagons.push(wagon);
            }
            let increment_ids = [];
            let product_orders = [];
            products = Object.values(products);
            products.sort((a, b) => b.qty - a.qty);
            for (let product of products) {
              let tr = create.tr({ style: 'vertical-align: top;' });
              if (
                path_data.no_pos.some(
                  (pos) => pos[0] == product.r && pos[0] == product.f
                )
              ) {
                tr.style.color = 'red';
              }
              for (let heading of headings.sum) {
                let td = create.td();
                let div = create.div({ style: 'top: 65px; position: sticky;' });
                switch (heading) {
                  case 'SKU': {
                    div.textContent = product.sku;
                    break;
                  }
                  case 'Product': {
                    div.textContent = product.name;
                    break;
                  }
                  case 'Quantity': {
                    div.textContent = product.qty;
                    break;
                  }
                  case 'Aisle': {
                    div.textContent = product.r;
                    break;
                  }
                  case 'Rack': {
                    div.textContent = product.f;
                    break;
                  }
                  case 'Shelf': {
                    div.textContent = product.h;
                  }
                }
                if (['Order number', 'Wagon'].includes(heading)) {
                  if (heading == 'Order number') {
                    product_orders = Object.values(orders).filter((order) =>
                      order.products.includes(product.sku)
                    );
                    increment_ids = product_orders.map(
                      (order) => `${order.company_id}:${order.increment_id}`
                    );
                  }
                  if (heading == 'Order number') {
                    for (let id of increment_ids) {
                      let line = create.div({ textContent: id });
                      div.append(line);
                    }
                  }
                  if (heading == 'Wagon') {
                    for (let order of product_orders) {
                      let line = create.div({
                        innerHTML: `#${order.wagon_number}&nbsp;`,
                        style:
                          'display: flex; white-space: pre; justify-content: flex-end;',
                      });
                      //let span = create.span({textContent: `(${order.wagon_pos})`, style: 'margin-left: auto;'});
                      //div.append(span);
                      div.append(line);
                    }
                  }
                }
                td.append(div);
                tr.append(td);
              }
              tbody.append(tr);
            }
            thead.append(tr);
            table.append(thead, tbody);
            sum.list.div.append(table);
            sum.div.append(sum.heading, sum.list.div);
            container.append(sum.div);
            for (let wagon of path.wagons) {
              let wagon_div = create.div({ className: 'wagon' });
              let list_div = create.div({ className: 'wagon-list' });
              let wagon_number = create.h4({
                textContent: `Wagon #${wagon.number}`,
              });
              table = create.table({
                className: 'list table odd-even',
                style: 'border-bottom: 2px solid lightgrey;',
              });
              thead = create.thead();
              tbody = create.tbody();
              tr = create.tr();
              Object.assign(wagon_number.style, {
                top: 0,
                zIndex: 3,
                position: 'sticky',
                background: 'white',
              });
              for (let heading of headings.path) {
                tr.append(
                  create.th({ textContent: heading, style: 'top: 23.19px;' })
                );
              }
              thead.append(tr);
              for (let item of wagon.list) {
                let tr = create.tr();
                for (let heading of headings.path) {
                  let td = create.td();
                  let div = create.div();
                  switch (heading) {
                    case 'Aisle': {
                      div.textContent = item.r;
                      break;
                    }
                    case 'Rack': {
                      div.textContent = item.f;
                      break;
                    }
                    case 'Shelf': {
                      div.textContent = item.h;
                      break;
                    }
                    case 'SKU': {
                      div.textContent = item.sku;
                      break;
                    }
                    case 'Product': {
                      div.textContent = item.name;
                      break;
                    }
                  }
                  td.append(div);
                  tr.append(td);
                }
                tbody.append(tr);
              }
              table.append(thead, tbody);
              list_div.append(table);
              wagon_div.append(wagon_number, list_div);
              container.append(wagon_div);
            }
          }
          delete validate_modal.esc;
          generating.remove();
          overlay.remove();
        });
      } else {
        this.$eventHub.$emit('ValidateModalStart', validate_modal);
      }
      this.$eventHub.$off('ValidateModalStop');
      this.$eventHub.$on('ValidateModalStop', async (approve) => {
      this.$eventHub.$off('ValidateModalStop');
        if (!approve) return;
        this.modal.create.loading = true;
        BPA.api
          .CreatePicklist(request)
          .then((response) => {
            return BPA.api.response({ response, return: 'text' });
          })
          .then((response) => {
            if (!response.ok || !response.result) {
              return (this.modal.create.loading = false);
            }
            if (action == 'download') {
              BPA.api
                .DownloadPicklist(response.result)
                .then((response) => {
                  return BPA.api.response({ response, return: 'blob' });
                })
                .then((response) => {
                  this.loading = false;
                  if (!response.ok || !response.result) return;
                  BPA.api.download({
                    name: `[PICK LIST] #${response.result}`,
                    blob: response.result,
                    new_tab: BPA.browser == 'firefox',
                  });
                  this.QueryLists();
                  this.$eventHub.$emit('CloseModal', 'create');
                })
                .catch((e) => e);
            } else if (action == 'print') {
              BPA.api
                .GetPicklistConfig()
                .then((config_response) => {
                  return BPA.api.response({ response: config_response, return: 'json' });
                })
                .then((config_response) => {
                  if (!config_response.ok)
                    throw new Error(
                      'Couldnt get pick list paperless configuration'
                    );

                    console.log(config_response, 'conf');
                    let print = null;
                  if (!config_response.result?.UsePaperlessOrders) {
                    print = BPA.api
                      .PrintPicklist({
                        list_id: config_response.result,
                        printer_id: printer_id,
                      })
                      .then((print_response) => {
                        return BPA.api.response({ response: print_response });
                      })
                  } else {
                    print = BPA.api
                      .PrintPicklistOverview({
                        list_id: response.result,
                        printer_id: printer_id,
                      })
                      .then((print_response) => {
                        return BPA.api.response({ response: print_response });
                      })
                  }

                  print.then((response) => {
                      this.modal.create.loading = false;
                      if (!response.ok) return;
                      this.$eventHub.$emit('ShowMessages', {
                        message:
                          'Pick list successfully created and sent to A4 printer',
                        type: 'success',
                        hide: 2000,
                      });
                      this.QueryLists();
                      this.$eventHub.$emit('CloseModal', 'create');
                    })
                    .catch((e) => e);
                })
                .catch(() => (this.modal.create.loading = false));
            } else {
              BPA.api
                .PrintPicklist({
                  list_id: response.result,
                  printer_id: printer_id,
                })
                .then((response) => {
                  return BPA.api.response({ response });
                })
                .then((response) => {
                  this.modal.create.loading = false;
                  if (!response.ok) return;
                  this.$eventHub.$emit('ShowMessages', {
                    message:
                      'Pick list successfully created and sent to A4 printer',
                    type: 'success',
                    hide: 2000,
                  });
                  this.QueryLists();
                  this.$eventHub.$emit('CloseModal', 'create');
                })
                .catch((e) => e);
            }
          })
          .catch((e) => e);
      });
    },
    async CreatePicklistPathPreview(request) {
      return await new Promise((resolve, reject) => {
        let preview = this.modal.create.strategy.preview;
        if (preview.request == JSON.stringify(request)) {
          return resolve(preview.data);
        }
        BPA.api
          .CreatePicklistPathPreview(request)
          .then((response) => {
            return BPA.api.response({ response, return: 'json' });
          })
          .then((response) => {
            if (!response.ok) return reject();
            preview.request = JSON.stringify(request);
            preview.data = response.result || {};
            resolve(preview.data);
          })
          .catch(reject);
      }).catch((e) => e);
    },
    async ViewListShow(list_id) {
      for (let i = 0; i < this.clickables.length; i++) {
        if (this.clickables[i].dataset.id == list_id) {
          this.clickables[i].classList.add('selected');
          if (this.cached.list && Object.keys(this.cached.list).length) {
            this.clickables[i].scrollIntoView({
              behavior: 'auto',
              block: 'center',
              inline: 'center',
            });
          }
          break;
        }
      }
      this.loading = true;
      let list = await this.GetPicklist(list_id);
      this.loading = false;
      let company_id = list.items[0].company_id;
      let company_code = this.GetCompanyById(company_id);
      const couriers = this.modal.create.couriers;
      company_code = String(company_code).toLowerCase();
      //if (!Object.keys(this.shipping).length) {
      await this.GetShippingMethods(company_code);
      //}
      list.items.sort(
        (a, b) => new Date(a.order_date) - new Date(b.order_date)
      );
      for (let item of list.items) {
        item.agent_code = this.shipping[item.shipping];
        let option = couriers.find((o) => o.code == item.agent_code);
        item.agent_name = option ? option.label : '';
        item.webshop = this.Webshop(item.company_id, item.increment_id);
      }
      BPA.cache.session({ name: this.$options.name, set: { list } });
      this.modal.view.list = list;
      this.modal.view.open = true;
    },
    async GetConfig() {
      return await BPA.api
        .GetPicklistConfig()
        .then((response) => {
          return BPA.api.response({ response, return: 'json' });
        })
        .then((response) => {
          if (!response.ok) return;
          this.config = response.result || {};
          this.config.UsePaperlessOrders = false;
          return this.config;
        })
        .catch((e) => e);
    },
    async GetPicklist(list_id) {
      return await new Promise((resolve, reject) => {
        if (this.list.id == list_id) return resolve(this.list);
        BPA.api
          .GetPicklist(list_id)
          .then((response) => {
            return BPA.api.response({ response, return: 'json' });
          })
          .then((response) => {
            if (!response.ok) return reject();
            this.list = response.result || {};
            resolve(this.list);
          })
          .catch(reject);
      }).catch((e) => e);
    },
    async GetLanguageOrderCount(company_code) {
      return await BPA.api
        .GetLanguageOrderCount(company_code)
        .then((response) => {
          return BPA.api.response({ response, return: 'json' });
        })
        .then((response) => response.result || {})
        .catch((e) => e);
    },
    async ViewPickPath(list) {
      if (!('order_by_data' in list)) {
        this.loading = true;
        list = await this.GetPicklist(list.id);
        this.loading = false;
      }
      let popup = {};
      let create = this.create;
      for (let div of [
        'overlay',
        'content',
        'titles',
        'title',
        'subtitle',
        'path-preview-container',
      ]) {
        let props = {};
        switch (div) {
          case 'overlay': {
            props.className = 'validate-popup fade-in';
            break;
          }
          case 'content': {
            props.className = 'validate-popup__content';
            props.style =
              'max-width: 100%; max-height: 100%; display: flex; flex-direction: column;';
            break;
          }
          default: {
            props.className = div;
            switch (div) {
              case 'title': {
                props.style = 'display: flex; align-items: center;';
                break;
              }
              case 'titles': {
                props.style =
                  'display: flex; flex-direction: column; overflow: hidden;';
                break;
              }
              case 'subtitle': {
                props.style =
                  'display: flex; flex-direction: column; overflow: hidden; margin-bottom: 0;';
                break;
              }
              case 'path-preview-container': {
                props.style =
                  'margin-top: 20px; overflow: hidden auto; white-space: initial;';
                div = 'container';
              }
            }
          }
        }
        popup[div.replace(/-/g, '_')] = create.div(props);
      }
      let strategy_option = this.modal.create.strategy.options.find((option) =>
        new RegExp(list.strategy, 'i').test(option.code)
      );
      let strategy_label = strategy_option ? strategy_option.label : 'Not set';
      let title_paragraph = create.p({
        textContent: `Pick list ID: ${list.id}`,
      });
      let subtitle_paragraph = create.p({
        innerHTML: `Path strategy: <b>${strategy_label}</b>`,
      });
      let close_button = create.div({
        className: 'close-popup',
        style:
          'width: 25px; height: 25px; position: relative; margin-left: auto; cursor: pointer;',
      });
      let close_button_div = create.div({
        style:
          'top: 50%; left: 50%; width: 25px; height: 3px; position: absolute; background: #606060; transform: translate(-50%, -50%) rotate(45deg); pointer-events: none;',
      });
      close_button_div = [
        close_button_div.cloneNode(),
        close_button_div.cloneNode(),
      ];
      close_button_div[1].style.transform =
        'translate(-50%, -50%) rotate(-45deg)';
      for (let div of close_button_div) close_button.append(div);
      let order_items = list.items;
      let path_data = list.order_by_data;
      let products_pos = path_data.products_pos_list;
      let wagon = {};
      let count = 1;
      let orders = {};
      let products = {};
      const wagon_number = () => Object.keys(wagon).length + 1;
      const add_products_to_wagon = (wagon) => {
        wagon[wagon_number()] = products;
        products = {};
        count = 0;
      };
      const find_product_wagon = (sku) => {
        for (let number in wagon) {
          if (Object.keys(wagon[number]).includes(sku)) {
            return { products: wagon[number], number };
          }
        }
      };
      for (let order of order_items) {
        orders[order.id] = order;
      }
      for (let id of path_data.sort.order_path) {
        let order = orders[id];
        order.wagon_number = wagon_number();
        for (let product of order.products) {
          let product_wagon = find_product_wagon(product.sku);
          if (product_wagon) {
            product_wagon.products[product.sku].qty += product.qty;
          } else {
            products[product.sku] = {
              ...product,
              ...products_pos[product.sku],
            };
            products_pos[product.sku] = products[product.sku];
          }
        }
        if (count == list.wagon_size) {
          add_products_to_wagon(wagon);
        }
        count++;
      }
      if (Object.keys(products).length) {
        add_products_to_wagon(wagon);
      }

      /*
        console.log('wagon', this.CloneObject(wagon))
        console.log('orders', this.CloneObject(orders))
        console.log('products', this.CloneObject(products))
        */

      /*
        let total_product_lines = 0;
        let total_product_items = 0;
        for (let order of order_items) {
          total_product_lines += order.products.length;
          for (let product of order.products) {
            total_product_items += product.qty;
          }
        }
        console.log('total_product_lines', total_product_lines)
        console.log('total_product_items', total_product_items)
        */

      //let total_length = 0;
      let path_wagons = {};
      let sku_path = path_data.sort.product_sku_path;
      /*
        for (let number in wagon) {
          let length = Object.keys(wagon[number]).length;
          total_length += length;
          console.log('wagon', number, length)
        }
        console.log('total_length', total_length)
        */

      for (let sku of sku_path) {
        let sku_wagon = find_product_wagon(sku);
        //console.log('wagon', sku_wagon.number, sku_wagon.products[sku])
        if (!Object.keys(path_wagons).includes(sku_wagon.number)) {
          path_wagons[sku_wagon.number] = [];
        }
        path_wagons[sku_wagon.number].push(sku_wagon.products[sku]);
      }
      
      //console.log('path_data.sort.product_sku_path', this.CloneObject(path_data.sort.product_sku_path))
      //console.log('path_wagons', this.CloneObject(path_wagons), Object.keys(path_wagons).map(number => Object.keys(path_wagons[number]).length).reduce((a, b) => a + b, 0))

      let table = create.table({
        className: 'list table odd-even',
        style: 'border-bottom: 2px solid lightgrey;',
      });
      let thead = create.thead();
      let tbody = create.tbody();
      let tr = create.tr();
      let sum = {
        div: create.div({ className: 'sum' }),
        heading: create.h4({
          textContent: 'Summation',
          style: 'top: 0; z-index: 3; position: sticky; background: white;',
        }),
        list: { div: create.div({ className: 'sum-list' }) },
      };
      let headings = {
        sum: [
          'SKU',
          'Product',
          'Quantity',
          'Aisle',
          'Rack',
          'Shelf',
          'Order number',
          'Wagon',
        ],
        path: ['Aisle', 'Rack', 'Shelf', 'SKU', 'Product'],
      };
      let path = { wagons: [] };
      for (let number in path_data.wagon) {
        let wagon = { number, list: [] };
        for (let sku of path_data.wagon[number]) {
          wagon.list.push(products[sku]);
        }
        path.wagons.push(wagon);
      }
      let increment_ids = [];
      let product_orders = [];
      for (let heading of headings.sum) {
        tr.append(create.th({ textContent: heading, style: 'top: 23.19px;' }));
      }
      popup.title.append(title_paragraph, close_button);
      popup.subtitle.append(subtitle_paragraph);
      products = Object.keys(path_wagons)
        .map((number) => Object.values(path_wagons[number]))
        .flat();
      products.sort((a, b) => b.qty - a.qty);
      for (let product of products) {
        let tr = create.tr({ style: 'vertical-align: top;' });
        if (
          (path_data.sort.no_pos || []).some(
            (pos) => pos[0] == product.reol && pos[1] == product.fag
          )
        ) {
          tr.style.color = 'red';
        }
        for (let heading of headings.sum) {
          let td = create.td();
          let div = create.div({ style: 'top: 65px; position: sticky;' });
          switch (heading) {
            case 'SKU': {
              div.textContent = product.sku;
              break;
            }
            case 'Product': {
              div.textContent = product.name;
              break;
            }
            case 'Quantity': {
              div.textContent = product.qty;
              break;
            }
            case 'Aisle': {
              div.textContent = product.reol;
              break;
            }
            case 'Rack': {
              div.textContent = product.fag;
              break;
            }
            case 'Shelf': {
              div.textContent = product.hylde;
            }
          }
          if (['Order number', 'Wagon'].includes(heading)) {
            if (heading == 'Order number') {
              product_orders = Object.values(orders).filter((order) =>
                order.products.some((prod) => prod.sku == product.sku)
              );
              increment_ids = product_orders.map(
                (order) => `${order.company_id}:${order.increment_id}`
              );
            }
            if (heading == 'Order number') {
              for (let id of increment_ids) {
                let line = create.div({ textContent: id });
                div.append(line);
              }
            }
            if (heading == 'Wagon') {
              for (let order of product_orders) {
                let line = create.div({
                  innerHTML: `#${order.wagon_number}&nbsp;`,
                  style:
                    'display: flex; white-space: pre; justify-content: flex-end;',
                });
                div.append(line);
              }
            }
          }
          td.append(div);
          tr.append(td);
        }
        tbody.append(tr);
      }
      thead.append(tr);
      table.append(thead, tbody);
      sum.list.div.append(table);
      sum.div.append(sum.heading, sum.list.div);
      popup.container.append(sum.div);
      /*
        console.log('products', this.CloneObject(products))
        console.log('path_data.sort.wagon', this.CloneObject(path_data.sort.wagon))
        console.log('path_wagons', this.CloneObject(path_wagons))
        console.log('products_pos', this.CloneObject(products_pos))
        */
      let map_to_reverse = Object.entries(path_data.sort.product_sku_path).map(
        ([key, value]) => { return [value, key]}
      )

      let sku_path_map = new Map(Object.entries(path_data.sort.product_sku_path))
      let sku_order_path_map = new Map(map_to_reverse)

      for (let number in path_data.sort.wagon || {}) {
        let wagon_list = path_data.sort.wagon[number];
        let wagon_div = create.div({ className: 'wagon' });
        let wagon_number = create.h4({ textContent: `Wagon #${number}` });
        let list_div = create.div({ className: 'wagon-list' });
        table = create.table({
          className: 'list table odd-even',
          style: 'border-bottom: 2px solid lightgrey;',
        });
        thead = create.thead();
        tbody = create.tbody();
        tr = create.tr();
        Object.assign(wagon_number.style, {
          top: 0,
          zIndex: 3,
          position: 'sticky',
          background: 'white',
        });
        for (let heading of headings.path) {
          tr.append(
            create.th({ textContent: heading, style: 'top: 23.19px;' })
          );
        }
        thead.append(tr);

        let sort_array = []
        for(let sku_in_wagon of wagon_list)
        {
          sort_array.push(sku_order_path_map.get(sku_in_wagon))
        }
      
        sort_array.sort((a, b) => {
          return (a - b)
        })
        
        let new_wagon = []

        for(let index of sort_array)
        {
          new_wagon.push(sku_path_map.get(index))
        }

        for (let sku of new_wagon) {
          let tr = create.tr();
          let product = products_pos[sku];
          for (let heading of headings.path) {
            let td = create.td();
            let div = create.div();
            switch (heading) {
              case 'Aisle': {
                div.textContent = product.reol;
                break;
              }
              case 'Rack': {
                div.textContent = product.fag;
                break;
              }
              case 'Shelf': {
                div.textContent = product.hylde;
                break;
              }
              case 'SKU': {
                div.textContent = product.sku;
                break;
              }
              case 'Product': {
                div.textContent = product.name;
                break;
              }
            }
            td.append(div);
            tr.append(td);
          }
          tbody.append(tr);
        }
        table.append(thead, tbody);
        list_div.append(table);
        wagon_div.append(wagon_number, list_div);
        popup.container.append(wagon_div);
      }
      popup.subtitle.append(popup.container);
      popup.titles.append(popup.title, popup.subtitle);
      popup.content.append(popup.titles);
      popup.overlay.append(popup.content);
      document.body.append(popup.overlay);
      setTimeout(() => {
        popup.overlay.classList.add('show');
      }, 150);
      let event = {
        close() {
          popup.overlay.classList.remove('show');
          setTimeout(() => {
            popup.overlay.remove();
          }, 150);
        },
        click(e) {
          let names = ['validate-popup', 'close-popup'];
          if ([...e.target.classList].some((name) => names.includes(name))) {
            event.listeners.remove();
            event.close();
          }
        },
        keydown(e) {
          if (/escape/i.test(e.key)) {
            event.listeners.remove();
            event.close();
          }
        },
        listeners: {
          add() {
            for (let listener of ['click', 'keydown']) {
              document.addEventListener(listener, event[listener], false);
            }
          },
          remove() {
            for (let listener of ['click', 'keydown']) {
              document.removeEventListener(listener, event[listener]);
            }
          },
        },
      };
      event.listeners.add();
    },
    async PrintPDF(list_id, is_paperless) {
      const printer_id = (BPA.printer.fetch('a4') || {}).id;
      if (!printer_id) {
        this.$eventHub.$emit('ShowMessages', {
          message: 'A4 printer is not selected',
          type: 'error',
          hide: 2000,
        });
        return;
      }
      await this.$eventHub.$emit('ValidateModalStart', {
        approve: 'Yes, print list',
        disapprove: 'No',
        message: 'Prints the current pick list.',
        type: 'success',
      });
      this.$eventHub.$on('ValidateModalStop', (approve) => {
        this.$eventHub.$off('ValidateModalStop');
        if (!approve) return;
        this.loading = true;
        if(is_paperless)
        {
          BPA.api
            .PrintPicklistOverview({
              list_id: list_id,
              printer_id: printer_id,
            })
            .then((response) => {
              return BPA.api.response({ response });
            })
            .then((response) => {
              this.loading = false;
              if (!response.ok) return;
              this.$eventHub.$emit('ShowMessages', {
                message: 'Pick list successfully sent to A4 printer',
                type: 'success',
                hide: 2000,
              });
              console.log(response, 'Response overview her')
            })
            .catch((e) => e);
        }
        else {
          BPA.api
            .PrintPicklist({
              list_id: list_id,
              printer_id: printer_id,
            })
            .then((response) => {
              return BPA.api.response({ response });
            })
            .then((response) => {
              this.loading = false;
              if (!response.ok) return;
              this.$eventHub.$emit('ShowMessages', {
                message: 'Pick list successfully sent to A4 printer',
                type: 'success',
                hide: 2000,
              });
            })
            .catch((e) => e);
        }
      });
    },
    async OpenPDF(shipment_id, is_paperless) {

      let blob = await this.GetPdfAsBlob(shipment_id, is_paperless);
      let blobURL = URL.createObjectURL(blob);
      window.open(blobURL);
      URL.revokeObjectURL(blobURL);
      
    },
    async DownloadPDF(list, is_paperless) {
      let blob = await this.GetPdfAsBlob(list.id, is_paperless);

      if (blob)
        BPA.api.download({
          blob: blob,
          name: `[PICK LIST] #${list.id} (${list.item_count}) - ${list.create_by} (${list.create_date})`,
          new_tab: BPA.browser == 'firefox',
        });
    },
    async GetPdfAsBlob(invoice_id, is_paperless) {
      if(is_paperless)
      {
        return await new Promise((resolve, reject) => {
        if (!invoice_id) return reject();
        this.loading = true;
        BPA.api
          .DownloadPicklistOverview(invoice_id)
          .then((response) => {
            return BPA.api.response({
              response,
              return: () => {
                return response.arrayBuffer();
              },
            });
          })
          .then((response) => {
            this.loading = false;
            if (!response.ok || !response.result) return reject();
            let arrayBuffer = response.result || {};
            resolve(new Blob([arrayBuffer], { type: 'application/pdf' }));
          })
          .catch(reject);
      }).catch((e) => e);
      }
      else {
        return await new Promise((resolve, reject) => {
          if (!invoice_id) return reject();
          this.loading = true;
          BPA.api
            .DownloadPicklist(invoice_id)
            .then((response) => {
              return BPA.api.response({
                response,
                return: () => {
                  return response.arrayBuffer();
                },
              });
            })
            .then((response) => {
              this.loading = false;
              if (!response.ok || !response.result) return reject();
              let arrayBuffer = response.result || {};
              resolve(new Blob([arrayBuffer], { type: 'application/pdf' }));
            })
            .catch(reject);
        }).catch((e) => e);
      }
    },
    SetOrderBy(value) {
      if (this.page.order.by == value) {
        if (this.page.order.direction == 'desc') {
          this.page.order.direction = 'asc';
        } else {
          this.page.order.direction = 'desc';
        }
      }
      this.page.order.by = value;
      this.QueryLists();
    },
    ClickOpen(e) {
      if (!e) return;
      let row = {};
      let elm = e.target;
      if (elm.localName != 'tr') row = elm.closest('tr');
      const filter = ['actions', 'icon dots'];
      const mousedown = (state) => {
        if (state === true || state === false) {
          row.mousedown = state;
        } else {
          return row.mousedown;
        }
      };
      for (let i = 0; i < this.clickables.length; i++) {
        if (this.clickables[i] != row) {
          this.clickables[i].classList.remove('hover', 'selected');
        }
      }
      if (elm.localName == 'a') return;
      if (filter.some((x) => new RegExp(x).test(elm.className))) return;
      if (e.type == 'mousedown') {
        mousedown(e.which == 1);
      }
      if (e.type == 'mousemove') {
        mousedown(false);
      }
      if (e.type == 'mouseup') {
        if (mousedown()) {
          this.ViewListShow(row.dataset.id);
        }
        mousedown(false);
      }
    },
    async ReleasePaperless(picklist_id, wagon_number) {
      await this.$eventHub.$emit('ValidateModalStart', {
        approve: 'Yes, release Paperless',
        disapprove: 'No, keep Paperless',
        message: 'Realeases current Paperless',
        type: 'danger',
      });

      this.$eventHub.$on('ValidateModalStop', async (approve) => {
        this.$eventHub.$off('ValidateModalStop');

        if (!approve) return;

        let response = await BPA.api.ReleasePaperlessCodes(
          picklist_id,
          wagon_number
        );

        if (response.status == 204) {
          this.$eventHub.$emit('ShowMessages', {
            message: 'Released Paperless',
            type: 'success',
            hide: 4000,
          });
        } else {
          this.$eventHub.$emit('ShowMessages', {
            message: 'Failed to release Paperless',
            type: 'error',
            hide: 4000,
          });
        }
      });
    },
    async BypassUsedInPacking(ordersList, isAll) {
      let orderIdsToBypass = this.PickOrderIDs(ordersList, isAll);

      this.$eventHub.$emit('ValidateModalStart', {
        approve: 'Bypass',
        disapprove: 'Abort',
        message: 'Bypass packing order',
        type: 'danger',
      });

      this.$eventHub.$on('ValidateModalStop', async (approve) => {
        this.$eventHub.$off('ValidateModalStop');

        if (!approve) return;

        let response = await BPA.api.BypassUsedInPacking(orderIdsToBypass);

        if (response.status == 204) {
          this.$eventHub.$emit('ShowMessages', {
            message: 'Bypassed complete',
            type: 'success',
            hide: 4000,
          });
        } else {
          this.$eventHub.$emit('ShowMessages', {
            message: 'Bypass failed',
            type: 'error',
            hide: 4000,
          });
        }
      });
    },
    PickOrderIDs(ordersList, isAll) {
      let orderIdList = [];
      if (!isAll) {
        let result = [];
        result = ordersList.filter((order) => order.bypass == true);
        ordersList = result;
      }
      ordersList.forEach((order) => {
        orderIdList.push(order.id);
      });
      return orderIdList;
    },
    async GenerateWarehouseMap(list_id, wagon_number) {
      
      let map_link = await new Promise((resolve, reject) => {
        BPA.api
          .GetDrawWarehouseMapLink(list_id, wagon_number)
          .then((response) => {
            return BPA.api.response({ response, return: 'text' });
          })
          .then((response) => {
            if (!response.ok) return reject();

            resolve(response.result);
          })
          .catch(reject);
      }).catch((e) => e);

      window.open(BPA.api.base().replace('/api/beautycos','') + map_link)
    },
  },
};
</script>

<style lang="scss">
@import '../../../assets/style/variables/colors';
@import '../../../assets/style/variables/icons';

.validate-popup.fade-in {
  $transition: opacity 0.15s ease, visibility 0s ease 0.15s;
  opacity: 0;
  visibility: hidden;
  transition: $transition;

  .validate-popup__content {
    opacity: 0;
    visibility: hidden;
    transition: $transition;
  }

  &.show {
    opacity: 1;
    visibility: visible;
    transition-delay: 0s;

    .validate-popup__content {
      opacity: 1;
      visibility: visible;
      transition-delay: 0s;
    }
  }
}

.label:hover {
  input[type='checkbox']:not(:disabled) ~ .v-checkbox-toggle {
    border-color: rgba(0, 0, 0, 0.5);
    cursor: pointer;
  }
}

.subtitle .path-preview-container {
  min-height: 50px;

  .wagon {
    margin-top: 20px;
    padding-top: 20px;
    border-top: 2px dashed #616161;
  }

  .generating {
    width: 100%;
    display: flex;
    font-size: 16px;
    font-weight: 600;
    padding: 20px 0 10px;

    &::before {
      content: '';
      width: 20px;
      height: 20px;
      margin-right: 10px;
      display: inline-flex;
      background-color: $textFont;
      -webkit-mask-size: contain;
      -webkit-mask-position: center;
      -webkit-mask-repeat: no-repeat;
      -webkit-mask-image: $route;
    }

    &::after {
      overflow: hidden;
      display: inline-flex;
      vertical-align: bottom;
      animation: ellipsis steps(4, end) 1.5s infinite;
      content: '\2026'; /* ascii code for the ellipsis character */
      width: 0px;
    }
  }

  @keyframes ellipsis {
    to {
      width: 16px;
    }
  }
}
.bypass-checkbox {
  display: flex;
  justify-content: center;
  margin-left: 3em;
  width: 1.5em;
  height: 1.5em;
}
.quantity-input {
  height: 34px;
  width: 65px;
  border: 1px solid rgba(60, 60, 60, 0.26);
  border-radius: 5px;
  font-size: large;
  text-align: center;
}
.quantity-input::-webkit-outer-spin-button,
.quantity-input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
</style>
